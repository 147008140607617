<script setup>
const store = useCommonStore();

const variant = ref(null);
watch(store, (s) => {
  variant.value = s.getTypeVariant;
});

const { toggleSearch } = store;

const props = defineProps(['isHomepage', 'specificNeedsSlug']);

const gtm = useGtm();
const { locale, locales } = useI18n();
const localePath = useLocalePath();

const availableLocales = computed(() => locales.value.filter((i) => i.code !== locale.value));

const trackHome = () => {
  gtm.trackEvent({ event: 'menuClick_home', url: window.location.pathname });
};
const track = (item, e) => {
  gtm.trackEvent({ event: 'menuClick_' + item, url: e.target.href.replace(window.location.origin, '') });
};
</script>

<template>
  <nav
    :class="{
      'top-menu--black': !isHomepage,
      [`top-menu--${variant}`]: isHomepage,
      'top-menu__is-homepage': isHomepage,
    }"
    class="top-menu"
  >
    <nuxt-link v-if="!isHomepage" :to="localePath('index')" @click="trackHome()" class="top-menu__link top-menu__link--home">
      <i class="icon-home" />
      <span class="hidden">{{ $t('global.top-menu.home') }}</span>
    </nuxt-link>

    <nuxt-link :to="localePath('club')" @click="track('clubNGP', $event)" class="top-menu__link">
      {{ $t('global.top-menu.club') }}
    </nuxt-link>
    <a :href="locale == 'cs' ? 'https://shop.ngprague.cz/' : 'https://www.shop-en.ngprague.cz/'" @click="track('shop', $event)" class="top-menu__link" target="_blank">
      {{ $t('global.top-menu.shop') }}
    </a>

    <a v-for="locale in availableLocales" :key="locale.code" :href="switchLocalePath(locale.code)" class="top-menu__link">{{ locale.name }}</a>

    <nuxt-link
      v-if="specificNeedsSlug"
      :to="{
        path: localePath({
          name: 'about-page-slug',
          params: { slug: specificNeedsSlug?.[locale] },
        }),
        hash: locale == 'cs' ? '#bnavstevnici-se-sluchovym-postizenim-informace-v-ceskem-znakovem-jazycebrb' : '#bvisitorsnbspbbwith-impaired-hearingb',
      }"
      class="top-menu__deaf-friendly"
    >
      <span class="deaf-icon"></span>
    </nuxt-link>

    <button class="top-menu__search" aria-label="Open search" @click="toggleSearch" @keyup.enter="toggleSearch" />
  </nav>
</template>

<style lang="scss" scoped>
.top-menu {
  $s: &;

  position: absolute;
  top: 25px;
  left: 40px;
  display: flex;
  align-items: center;
  z-index: 5;
  transition:
    color 0.5s,
    left 0.5s,
    transform 0.5s;
  white-space: nowrap;

  &__is-homepage {
    left: auto;
    right: 40px;
  }

  &--white,
  &--border {
    color: $white;

    #{$s}__search,
    #{$s}__deaf-friendly .deaf-icon {
      filter: invert(1);
    }
  }

  &__link {
    @include underline-transition(1px);
    margin-right: 16px;
    font-size: 12px;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    text-decoration: none;

    &--home {
      background-size: 0% 0% !important;
      height: 13px;
      width: 13px;
      transition: transform 0.1s;

      .icon-home {
        pointer-events: none;
      }

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  &__deaf-friendly {
    margin-right: 14px;

    .deaf-icon {
      display: inline-block;
      width: 22px;
      height: 22px;
      mask-image: url('/img/deaf-friendly.svg');
      -webkit-mask-image: url('/img/deaf-friendly.svg');

      background-color: black;
    }
  }

  &__search {
    @include button-reset;
    width: 25px;
    height: 25px;
    margin-left: -(25px - 18px);
    background: url('/img/ico-search.svg') center no-repeat;
    background-size: 18px;
    cursor: pointer;
    transition:
      transform 0.15s,
      filter 0.5s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .top-menu--black {
    color: black !important;

    #{$s}__search,
    #{$s}__deaf-friendly .deaf-icon {
      filter: none;
    }
  }

  .top-menu--white {
    color: white;

    #{$s}__search,
    #{$s}__deaf-friendly .deaf-icon {
      filter: invert(1);
    }
  }

  .top-menu--white .top-menu__deaf-friendly .deaf-icon {
    background-color: #fff;
  }

  .top-menu--black .top-menu__deaf-friendly .deaf-icon {
    background-color: #000;
  }
}
</style>
